import React, { Component } from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import { Element, scroller, scrollSpy } from 'react-scroll'

import Layout from '../components/layouts/Layout'
import Accueil from '../components/home/Accueil'

class IndexPage extends Component {
  componentDidMount() {
    scrollSpy.update()

    let hash = window.location.hash
    hash = hash.replace('#', '')

    if (hash && hash != '') {
      console.log('hash : ' + hash)
      scroller.scrollTo(hash)
    }
  }
  render() {
    
    const imagesMethode = {
      depoussierage: this.props.data.depoussierage.childImageSharp,
    }

    return (
      <Layout>
        <Accueil />
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query ImageQuery {
    depoussierage: file(relativePath: { eq: "home/depoussierage.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`