import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { Grid, Section, Paper, Button, ImageBasic } from 'inno-components'
import Config from '../../Config'

const styles = StyleSheet.create({
  paper: {
    display: 'block',
    maxWidth: '300px',
    margin: '0.5em auto',
    width: '100%',
    ':before': {
      borderColor: '#F3F3F3',
    },
  },
  image: {
    width: '100%',
    objectFit: 'cover',
    height: '200px',
  },
  title: {
    margin: '0.2em 0',
    textAlign: 'center',
    color: Config.colors.primary,
    textTransform: 'uppercase',
  },
  list: {
    listStyleType: 'none',
    textAlign: 'center',
    padding: 0,
    margin: '0.5em 0',
  },
})

const Montjoie = () => (
  <Paper
    style={{ borderRadius: '0.5em', overflow: 'hidden' }}
    className={`${css(styles.paper)} hvr-ripple-out`}
  >
    <ImageBasic
      className={css(styles.image)}
      src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/images/photo50.jpg"
    />
    <div style={{ padding: '1em' }}>
      <h4 className={css(styles.title)}>
        PADEL TENNIS CLUB <br />
        MONTJOIE
      </h4>
      <ul className={css(styles.list)}>
        <li>Rue Edith Cavell, 91</li>
        <li>1180- Bruxelles</li>
        <li>0470/96 37 09</li>
      </ul>
    </div>
  </Paper>
)

export default Montjoie
