const config = {
  url: 'https://www.aliaslanguage.be',
  name: 'bts',
  head: {
    image: 'https://www.alias-language.be/images/Logo.jpg',
  },
  facebook: {
    prefix:
      'og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# business: http://ogp.me/ns/business#"',
    type: 'business.business',
  },
  colors: {
    primary: '#022B47',
    secondary: '#E67E21',
    thirdary: '#E7F1FE',
  },
  langs: {
    default: 'fr',
    locales: ['fr', 'en', 'nl'],
  },
  fonts: {
    defaultSize: '18px',
    primary: {
      name: "'Open Sans Condensed', sans-serif",
      url:
        'https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300i,700',
    },
    secondary: {
      name: "'PT Sans', sans-serif",
      url: 'https://fonts.googleapis.com/css?family=PT+Sans',
    },
  },
}

export default config
