import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StyleRoot } from 'radium'
import { MuiThemeProvider, CssBaseline, Favicon } from 'inno-components'

const Layout = ({ children }) => {
  return (
    <StyleRoot>
      <CssBaseline />
      <Helmet
        title="Brussels Tennis School - école et cours de tennis - Bruxelles"
        meta={[
          {
            name: 'description',
            content:
              'Brussels Tennis School - école, leçons, cours de tennis pour enfants et adultes à Bruxelles - 1180 Uccle',
          },
        ]}
      >
        <html lang="fr" />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.3.1/components/grid.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://storage.googleapis.com/innobelge-websites.appspot.com/commons/styles/hover.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/photoswipe/4.1.2/photoswipe.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/photoswipe/4.1.2/default-skin/default-skin.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/imagehover.css/1.0/css/imagehover.min.css"
        />
      </Helmet>
      {children}
    </StyleRoot>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
